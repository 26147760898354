import "tiny-slider/dist/tiny-slider.css";
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";

jQuery(document).ready(function () {
  let sliderMain = document.querySelectorAll(".carousel-cards");
  Array.prototype.slice.call(sliderMain).forEach(function (slider_main) {
    // const edgePadding = slider_center.getAttribute("data-edge-padding") || 50;
    const elementLeft = slider_main.parentNode.parentNode.querySelector(
      ".controls__button--left"
    );
    const elementRight = slider_main.parentNode.parentNode.querySelector(
      ".controls__button--right"
    );
    const elementNav =
      slider_main.parentNode.parentNode.querySelector(".controls__dots");
    const sliderTns = tns({
      container: slider_main,
      items: 1,
      mode: "gallery",
      autoplay: false,
      autoplayButtonOutput: false,
      controlsPosition: "bottom",
      mouseDrag: false,
      arrowKeys: true,
      center: true,
      gutter: 10,
      edgePadding: 0,
      touch: false,
      navContainer: elementNav,
      prevButton: elementLeft,
      nextButton: elementRight,

      onInit: function (slider_main) {
        const sliderContainer =
          slider_main.container.parentNode.parentNode.childNodes;
        if (sliderContainer && sliderContainer.length) {
          sliderContainer.forEach((element) => {
            element.removeAttribute("aria-live");
            element.removeAttribute("aria-atomic");
          });
        }
        slider_main.nextButton.setAttribute("tabindex", 0);
        slider_main.prevButton.setAttribute("tabindex", 0);
        removeAttributesfromSlider(slider_main.navItems);
      },
    });

    sliderTns.events.on("indexChanged", function (info) {
      const activeSlideElement = info.container.querySelector(
        ".tns-item:nth-child(" + info.displayIndex + ")"
      );
      const focusableElements = activeSlideElement.querySelectorAll(
        "a[href], input, select, textarea, button, iframe, [tabindex]"
      );
      focusableElements[0].focus();
      removeAttributesfromSlider(info.navItems);
    });
    sliderTns.events.on("transitionEnd", function (info) {
      setTabindexInnerElements(sliderTns);
    });
    setTabindexInnerElements(sliderTns);
  });

  function setTabindexInnerElements(slider) {
    const slides = Array.from(slider.getInfo().slideItems);
    slides.forEach((slider) => {
      var focusableElements = slider.querySelectorAll(
        "a[href], input, select, textarea, button, iframe, [tabindex]"
      );

      if (!slider.classList.contains("tns-slide-active")) {
        focusableElements.forEach((element) => {
          element.tabIndex = -1;
        });
      } else {
        focusableElements.forEach((element) => {
          if (!element.classList.contains("card-cta-overlay")) {
            element.tabIndex = 0;
          }
        });
      }
    });
  }

  function removeAttributesfromSlider(slider) {
    Array.from(slider).forEach((attribute) => {
      attribute.removeAttribute("aria-label");
      attribute.removeAttribute("aria-controls");
    });
  }
});
